import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../index.css";
import { FBUser, User } from "../data/Types";
import { Button, Form, Modal } from "react-bootstrap";
import { signOutAll, updateUser, verifySecret } from "../firebase";

interface Props {
	users: User[];
}

export default (props: Props) => {
	const nav = useNavigate();
	const [show, setShow] = useState<boolean>(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [success, setSuccess] = useState(false);

  const change = () => {
    props.users.forEach((user) => {
      user.volunteer = 0;

      const numUser = user as FBUser

      numUser.meetings = Object.fromEntries(Object(numUser.meetings));
      updateUser(numUser)
    })
  }

	return (
		<>
			<Button className="action" onClick={handleShow}>Options</Button>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Actions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
            <Form.Group>
              <Button className="action" onClick={() => signOutAll(props.users)}>Sign Out All</Button>
              <Button className="action" onClick={async () => setSuccess(await verifySecret())}>Verify Secret Code</Button>
              <Button className="action" onClick={() => nav("/")}>Refresh Token</Button>
              <Button className="action" onClick={() => change()}>Change to number</Button>
            </Form.Group>
			    <h4 style={{ "color": success ? "green" : "red" }}>{success ? "Successful verification" : "Not verified or invalid token (login again)"}</h4>
          </Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
